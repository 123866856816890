body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #232f3e;
}
.container {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.data {
  text-align: center;

  color: #ff9900;
  font-family: "Lato", sans-serif;
  font-size: 8em;
  letter-spacing: 10px;
  font-weight: 900;
}
.quote {
  text-align: center;
  color: #fff;
  font-family: ubuntu, poppins;
  font-size: 2em;
  letter-spacing: 10px;
  font-weight: 600;
}

.heart {
  text-align: center;
  color: #7f00ff;

  font-size: 4em;

  font-weight: 600;
}
